<template>
  <div class="ideeen">
    <header>
      <div>
        <img
          @click="$router.go(-1)"
          src="@assets/back.png"
          class="back-button"
        />
        <img src="@assets/ideeen/header.png" />
      </div>
    </header>

    <div class="uk-container">
      <div class="uk-grid-large uk-child-width-1-1" uk-grid>
      </div>
    </div>

    <footer class="uk-padding-large">
      <div>
        <img
          @click="$router.go(-1)"
          src="@assets/back.png"
          class="back-button"
        />
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.ideeen {
  min-height: 100vh;
  background: rgb(31, 101, 167);
}

.header {
  width: 30rem;
}
</style>
